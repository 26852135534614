<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">

        <!-- Searchbar Input -->
        <ion-toolbar v-if="showSearchBar">
          <ion-searchbar style="padding-top: 12px" mode="ios" ref="searchBarRef" v-model="searchKeyword" :placeholder="t('search')"
                        :cancel-button-text="t('cancel')" show-cancel-button="always" @ionCancel="showSearchBar = false"></ion-searchbar>
        </ion-toolbar>

        <!-- Page title & action buttons -->
        <ion-toolbar v-else>
          <ion-buttons slot="start">
            <ion-back-button default-href="/info-overview"></ion-back-button>
          </ion-buttons>

          <ion-title>{{ t('infos') }}</ion-title>

          <ion-buttons slot="primary">
            <ion-button router-link="/browsed-infos">
              <ion-icon slot="icon-only" :icon="timeOutline"></ion-icon>
            </ion-button>
            <ion-button router-link="/liked-infos">
              <ion-icon slot="icon-only" :icon="bookmarksOutline"></ion-icon>
            </ion-button>
            <ion-button @click="onClickSearchIcon()">
              <ion-icon slot="icon-only" :icon="search"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>

        <!-- Information type filter -->
        <ion-toolbar>
          <ion-segment v-model="typeFilter" @ionChange="numOfVisibleItems = 20" scrollable>
            <ion-segment-button value="all">
              <ion-label style="margin-bottom: 6px">{{ t('all') }}</ion-label>
            </ion-segment-button>
            <ion-segment-button v-for="type in infoTypes" :key="type" :value="type">
              <ion-label style="margin-bottom: 6px">{{ type }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <!-- Keyword filter -->
        <ion-toolbar>
          <div class="horizontal-scroll">
            <ion-chip v-for="keyword in filteredKeywords(typeFilter)" :key="keyword.name" @click="selectedKeyword = keyword.name"
                      :outline="selectedKeyword != keyword.name">
              <ion-label>{{ keyword.name }} ({{ keyword.numOfInfo }})</ion-label>
            </ion-chip>
          </div>
        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content>

      <ion-grid fixed>

        <!-- Loading Data -->
        <loading-skeleton v-if="loading"></loading-skeleton>

        <!-- Data loaded -->
        <div v-else>
          <ion-list>
            <info-item v-for="(info, i) in filterInfos(typeFilter, selectedKeyword).slice(0, numOfVisibleItems)"
                      :key="i" :info="info" :infoSources="infoSources" :userLoggedIn="userLoggedIn"></info-item>
          </ion-list>

          <ion-infinite-scroll
            @ionInfinite="loadData($event)" 
            threshold="100px" 
            id="infinite-scroll"
          >
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              :loading-text="t('loadingMoreInfos')">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
         swapVerticalOutline, trashOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonModal, IonImg,
        IonSegment, IonSegmentButton, IonChip, IonBackButton, IonCheckbox, IonBadge,
        IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption,
        loadingController, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import InfoItem from "@/components/info/InfoItem.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: 'InfoListPage',
  components: { IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonCheckbox, IonBadge, IonModal, IonImg,
                IonSegment, IonSegmentButton, IonChip, IonBackButton,
                IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption,
                LoadingSkeleton, InfoItem, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { openBrowser, infiniteScrollLoadData, } = utils();

    const { targetInfoLink } = history.state;

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const userLatLong = computed(() => store.state.userLatLong);
    const loading = computed(() => store.state.loadingAppPublicData);
    const infos = computed(() => store.state.infos);
    const infoTypes = computed(() => store.state.infoTypes);
    const infoKeywords = computed(() => store.state.infoKeywords);
    const infoSources = computed(() => store.state.infoSources);

    const searchBarRef = ref(null);
    const showSearchBar = ref(false);
    const searchKeyword = ref("");
    const numOfVisibleItems = ref(20);
    const typeFilter = ref("all");
    const selectedKeyword = ref("all");

    const onClickSearchIcon = () => {
      showSearchBar.value = true;
      setTimeout(() => {
        searchBarRef.value.$el.setFocus();
      }, 100);
    }

    const filterInfos = (typeFilter, selectedKeyword) => {
      typeFilter = typeFilter || "";
      return infos.value.filter(info => {
        return (searchKeyword.value == "" || info.title.includes(searchKeyword.value)) && 
                (typeFilter.toLowerCase() == 'all' || info.type.includes(typeFilter)) &&
                (selectedKeyword == 'all' || info.keyword == selectedKeyword);
      });
    }
    const filteredKeywords = (typeFilter) => {
      const tmpObj = {};
      filterInfos(typeFilter, 'all').forEach(info => {
        const keyword = info.keyword;
        if (keyword) tmpObj[keyword] = (tmpObj[keyword] || 0) + 1;
      });
      return Object.keys(tmpObj).map(key => {
        return { name: key, numOfInfo: tmpObj[key] };
      });
    }
    
    const loadData = (ev) => {
      const filteredInfos = filterInfos(typeFilter.value, selectedKeyword.value);
      infiniteScrollLoadData(ev, numOfVisibleItems, filteredInfos);
    }

    if (targetInfoLink) {
      setTimeout(() => {
        openBrowser(targetInfoLink);
      }, 1000);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, onClickSearchIcon, loadData,
      filterInfos, filteredKeywords,

      // icons
      add, close, search, navigate, arrowBack, bookmarksOutline, timeOutline,
      swapVerticalOutline, trashOutline,

      // variables
      loading, currUser, userLoggedIn,
      infos, infoTypes, infoKeywords, infoSources,
      typeFilter, selectedKeyword, numOfVisibleItems,
      searchBarRef, showSearchBar, searchKeyword,
    }
  }
}
</script>

<style scoped>
</style>